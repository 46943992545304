<template>
  <v-dialog v-model="mostrar" fullscreen persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat>
        <h3>Agregar consejeros mediante CSV</h3>
        <v-spacer></v-spacer>
        <v-btn small outlined :loading="loading" @click="$emit('cancelar')"
          >Cerrar</v-btn
        >
      </v-toolbar>
      <v-container>
        <v-card outlined>
          <v-card-title>
            <v-file-input
              label="Seleccione archivo"
              dense
              truncate-length="50"
              outlined
              :loading="loadingFile"
              :disabled="loadingFile || loading"
              :error-messages="fileError"
              :hint="inputHint"
              persistent-hint
              @change="archivoSeleccionado"
            >
            </v-file-input>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              small
              :class="{ 'disable-events': !puedeAgregar || loading }"
              @click="agregarConsejeros()"
              >Agregar Consejeros</v-btn
            >
          </v-card-title>
          <v-divider class="mx-3"></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Código</th>
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Suplente</th>
                  <th class="text-left">Codigo</th>
                  <!-- <th class="text-left">Correo</th> -->
                  <!-- <th class="text-left">Status</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(consejero, index) in consejeros" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ consejero.codigo }}</td>
                  <td>{{ consejero.nombre }}</td>
                  <td>{{ consejero.suplente.nombre }}</td>
                  <td>{{ consejero.suplente.codigo }}</td>
                  <!-- <td v-if="index != 0">{{ consejero.correo }}</td> -->
                  <!-- <td
                    v-if="index != 0"
                    :class="{ 'error-text': consejero.error }"
                  >
                    {{ consejero.status }}
                  </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
// import { mapGetters } from "vuex";
// import { validationMixin } from "vuelidate";

import {
  agregarConsejeroService,
  agregarSuplenteCSV,
  subirCSV,
} from "./consejos.service";

export default {
  name: "agregarConsejerosCSV",
  props: ["mostrar", "idConsejo"],
  data() {
    return {
      loading: false,
      loadingFile: false,
      fileError: null,
      puedeAgregar: false,
      inputHint: "",
      consejeros: [],
    };
  },
  methods: {
    async archivoSeleccionado(file) {
      if (file) {
        this.fileError = null;
        try {
          if (file.type !== "text/csv")
            throw new Error("No se seleccionó un archivo CSV");
          const archivo = new FormData();
          archivo.append("file", file);
          this.loadingFile = true;
          const serverResponse = await subirCSV(archivo);
          this.loadingFile = false;
          if (!serverResponse.ok) this.fileError = serverResponse.mensaje;
          else {
            this.consejeros = serverResponse.consejeros;
            if (this.consejeros.length > 0) this.puedeAgregar = true;
          }
          this.consejeros.shift();
          this.consejeros.forEach(consejero => {
          
            consejero.suplente = {
              codigo: consejero.supCodigo,
              nombre: consejero.supNombre,
              categoria: consejero.supCategoria,
              correo: consejero.supCorreo
            };

            delete consejero.supCategoria;
            delete consejero.supCodigo;
            delete consejero.supCorreo;
            delete consejero.supNombre;
          });

          this.inputHint = `Registros: ${this.consejeros.length}`;
        } catch (error) {
          this.loadingFile = false;
          this.fileError = error.message;
        }
      }
    },

    async agregarConsejeros() {
      const noEnMaster = "El usuario no se encuentra en máster";
      const yaAsignado = "El usuario ya se encuentra asignado al consejo";
      let consejerosErroneos = [];
      let consejerosNuevos = [];
      try{
      for (let i = 0; i < this.consejeros.length; i++) {
        let consejero = this.consejeros[i];
  
        const sendData = {
          idConsejo: this.idConsejo,
          codigo: consejero.codigo,
          categoria: consejero.categoria,
          dependencia: consejero.dependencia,
          divisionDepto: consejero.divisionDepto,
        };
        this.inputHint = `Procesando: ${i + 1}/${this.consejeros.length}`;
        this.loading = true;
        // try {
          const serverResponse = await agregarConsejeroService(sendData);
          let suplenteAgregado=null
          if (serverResponse.ok) {
            // consejero.status = serverResponse.mensaje;
            consejero.usuarioMaster = serverResponse.consejero.usuarioMaster;
            consejero._id = serverResponse.consejero._id;
            // const sendDataSuplente = {
            //   idConsejero:consejero._id,
            //   codigo:consejero.suplente.codigo,
            //   categoria:consejero.suplente.categoria,
            //   correo: consejero.suplente.correo

            // }

            if(consejero.suplente.codigo!=""){
            suplenteAgregado = await this.agregarSuplente(consejero);

            if (!suplenteAgregado.ok){
              this.consejeros[i].status = suplenteAgregado.mensaje;
              this.consejeros[i].error = true;

            }
            consejero.suplente = suplenteAgregado.consejero
            }

          } else {
            consejero.status = serverResponse.mensaje;
            consejero.error = true;
            
          }

          if (consejero.error && consejero.status == noEnMaster)
            consejerosErroneos.push(consejero);
          if (consejero.status != yaAsignado && consejero.status != noEnMaster)
            consejerosNuevos.push(consejero);

          this.consejeros.splice(i, 1, consejero);
          this.loading = false;
        // } catch (error) {
        //   console.log(error);
        //   this.consejeros[i].status = error.message;
        //   this.consejeros[i].error = true;
        //   this.loading = false;
        // }
      }
      const emitData = {
        consejero: consejerosNuevos,
        consejerosErroneos: consejerosErroneos,
      };

      this.$emit("consejeroAgregado", emitData);
      // consejerosNuevos.forEach(consejero => {
      //   this.agregarSuplente(consejero);
      // });
      this.inputHint = `Registros: ${this.consejeros.length}`;
      }catch(error){
       this.loading = false;
        this.$notificarError(error);
      }
    },
    async agregarSuplente(consejero) {

      if (consejero.suplente.codigo == "") return;
      const sendData = {
        idConsejero: consejero._id,
        tipo: "suplente",
        codigo: consejero.suplente.codigo,
        categoria: consejero.suplente.categoria,
        correo:consejero.suplente.correo? consejero.suplente.correo:'sincorreo@correo.com'
      };

      const serverResponse = await agregarSuplenteCSV(sendData, this.idConsejo);
   
      return serverResponse
      // if(!serverResponse.ok)
      // {
      //   consejero.suplente.status = serverResponse.mensaje;
      //   consejero.suplente.error = true;
      // }
      // const emitData = {
      //   ...sendData,
      //   usuarioMaster: serverResponse.usuarioMaster,
      //   fromCSV: true,
      // };
      // this.$emit("consejeroModificado", emitData);
    }
  },
};
</script>

<style>
.error-text {
  color: brown;
}
</style>