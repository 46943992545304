<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-toolbar elevation="1" color="secondary" dense dark>
              <v-btn icon @click="$router.go(-1)">
                <v-icon>mdi-arrow-left-bold</v-icon>
              </v-btn>
              <v-toolbar-title class="text-h5">{{
                consejo.nombre
              }}</v-toolbar-title>
            </v-toolbar>
            <v-tabs
                v-model="tab"
                background-color="primary"
                center-active
                dark
              >
                <v-tabs-slider color="secondary"></v-tabs-slider>
                <v-tab>CONSEJEROS</v-tab>
                <v-tab>CORREO</v-tab>
                <v-tab v-if="consejo.correo?.asunto!=null">CONSTANCIAS</v-tab>

              </v-tabs>
            <div  v-if="tab==0">
            <v-col>
              <v-card flat>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  
                    <v-menu offset-y left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon>mdi-account-cog</v-icon></v-btn
                        >
                      </template>
                      <v-list dense color="grey lighten-4">
                        <v-list-item @click="agregarConsejero = true">
                          <v-list-item-title>Agregar Consejero</v-list-item-title>
                        </v-list-item>
                        <v-divider class="mx-3"></v-divider>
                        <v-list-item @click="agregarCSV = true">
                          <v-list-item-title>Agregar CSV</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                   
         
                </v-card-actions>
              </v-card>
            </v-col>

            <v-card-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar por nombre, código, correo o dependencia"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
             
              :headers="tableHeaders"
              :loading="loading"
              :search="search"
              :items="consejerosLocal"
              class="mx-3"
              :items-per-page="50"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100, 200, -1],
                itemsPerPageAllText: 'Todos',
                itemsPerPageText: 'Elementos por página',
              }"
            >
              <template v-slot:body="{ items }">
                <tr v-for="(consejero, index) in items" :key="`row-${index}`">
                  <td>{{ consejero.numero }}</td>
                  <td style="min-width: 200px; max-width: 400px">
                    <consejeroItemComponent :consejero="consejero.propietario" />
                  </td>
                  <td style="min-width: 200px; max-width: 400px">
                    <consejeroItemComponent :consejero="consejero.suplente" />
                  </td>
                  <td>{{ consejero.dependencia }}</td>
                  <td>{{ consejero.divisionDepto }}</td>
                  <td>
                    <consejeroMenuComponent
                      :consejero="consejero"
                      @modificarDatos="modificarDatosAction"
                      @asignarPropietario="asignarPropietarioAction"
                      @asignarSuplente="asignarSuplenteAction"
                      @eliminarSuplente="eliminarSuplenteAction"
                    />
                  </td>
                </tr>
              </template>
            </v-data-table>
            </div>
             <constancias-component
              v-if="tab==2"
              :enviando="enviando"
              @enviando="e=>enviando=e"
              :constanciasGeneradas="consejo.constancias"
              :constanciasEnviadas="consejo.constanciasEnviadas"
              @constanciasGeneradas="e=>consejo.constancias=e"
              @constanciasEnviadas="e=>consejo.constanciasEnviadas=e"
            />
            <EditarCorreo
            v-if="tab==1"
            :consejo="consejo"
            @modificarCorreo="modificarCorreo"
            >

            </EditarCorreo>

          </v-card>
        </v-col>
      </v-row>
      <agregarConsejeroComponent
        v-if="agregarConsejero"
        :mostrar="agregarConsejero"
        :idConsejo="idConsejo"
        @cancelar="agregarConsejero = false"
        @consejeroAgregado="consejeroAgregado"
      />

      <agregarConsejeroCSVComponent
        v-if="agregarCSV"
        :mostrar="agregarCSV"
        :idConsejo="idConsejo"
        @cancelar="agregarCSV = false"
        @consejeroAgregado="consejeroAgregado"
        @consejeroModificado="consejeroModificado"
      />

      <consejerosErroneosComponent
        v-if="consejerosErroneos.length > 0"
        :mostrar="mostrarConsejerosErroneos"
        :consejeros="consejerosErroneos"
        @cancelar="mostrarConsejerosErroneos = false"
      />

      <modificarConsejeroComponent
        v-if="modificarConsejero.mostrar"
        :mostrar="modificarConsejero.mostrar"
        :idConsejero="modificarConsejero.idConsejero"
        :tipo="modificarConsejero.tipo"
        @cancelar="limpiarModificarConsejero"
        @consejeroModificado="consejeroModificado"
      />

      <modificarDatosConsejeroComponent
        v-if="modificarDatos.mostrar"
        :mostrar="modificarDatos.mostrar"
        :idConsejero="modificarDatos.idConsejero"
        :dependenciaPadre="modificarDatos.dependencia"
        :divisionDeptoPadre="modificarDatos.divisionDepto"
        :dependencias="dependencias"
        @cancelar="limpiarModificarDatos()"
        @datosModificados="datosModificados"
      />

      <eliminarSuplenteComponent
        v-if="eliminarSuplente.mostrar"
        :mostrar="eliminarSuplente.mostrar"
        :idConsejero="eliminarSuplente.idConsejero"
        :suplente="eliminarSuplente.suplente"
        @cancelar="limpiarEliminarSuplente"
        @consejeroModificado="consejeroModificado"
      />

  
    </v-container>
  </v-main>
</template>
<script>
import { mapGetters } from "vuex";
import { getConsejoService } from "./consejos.service";
import consejeroItemComponent from "./consejeroItem.vue";
import consejeroMenuComponent from "./consejeroMenu.vue";
import agregarConsejeroComponent from "./agregarConsejero.vue";
import modificarConsejeroComponent from "./modificarConsejero.vue";
import modificarDatosConsejeroComponent from "./modificarDatos.vue";
import eliminarSuplenteComponent from "./eliminarSuplente.vue";
import constanciasComponent from "./constancias/constancias.vue";
import EditarCorreo from "./editarCorreo.vue";
// import consejerosMenuComponent from "./consejerosMenu.vue";
import agregarConsejeroCSVComponent from "./agregarConsejeroCSV.vue";
import consejerosErroneosComponent from "./consejerosErroneos.vue";
// import agregarConsejeroCSVVue from './agregarConsejeroCSV.vue';

export default {
  name: "consejo",
  components: {
    consejeroItemComponent,
    consejeroMenuComponent,
    agregarConsejeroComponent,
    modificarConsejeroComponent,
    modificarDatosConsejeroComponent,
    eliminarSuplenteComponent,
    constanciasComponent,
    EditarCorreo,
    // consejerosMenuComponent,
    agregarConsejeroCSVComponent,
    consejerosErroneosComponent,
  },
  computed: {
    ...mapGetters(["userAdmin"]),
    consejerosLocal() {
      const consejeros = [];
      for (let i = 0; i < this.consejeros.length; i++) {
        const consejero = this.consejeros[i];
        const item = {
          numero: i + 1,
          _id: consejero._id,
          dependencia: consejero.dependencia?.nombre?? "",
          idDependencia: consejero.dependencia?._id?? "",
          divisionDepto: consejero.divisionDepto,
          propietario: {
            nombre: consejero.usuarioMaster?.nombre?? null,
            categoria: consejero.categoria,
            correo: consejero.usuarioMaster.correo,
            codigo:consejero.usuarioMaster.codigo,
          },
          propietarioBusqueda:consejero.usuarioMaster.nombre + consejero.usuarioMaster.correo + consejero.usuarioMaster.codigo,
          // nombrePropietario:consejero.usuarioMaster.nombre,
          
          suplente: {
            nombre: consejero.suplente.usuarioMaster ? consejero.suplente.usuarioMaster.nombre : null,
            categoria: consejero.suplente.usuarioMaster ? consejero.suplente.categoria : null,
            correo: consejero.suplente.usuarioMaster ? consejero.suplente.usuarioMaster.correo : null,
            codigo:consejero.suplente.usuarioMaster ? consejero.suplente.usuarioMaster.codigo : null
          },
          suplenteBusqueda:consejero.suplente?.usuarioMaster ? consejero.suplente.usuarioMaster.nombre + consejero.suplente.usuarioMaster.correo + consejero.suplente.usuarioMaster.codigo : ""

        };
        consejeros.push(item);
        // console.log(consejeros);
      }
      return consejeros;
    },
  },
  mounted(){
    this.idConsejo = this.$route.params.idConsejo;
    this.cargarConsejo();

    this.$socket.on('enviando', env =>{
      this.enviando = env
    })
  },
  data() {
    return {
      loading: false,
      idConsejo: null,
      search: "",
      consejo: {},
      consejeros: [],
      consejerosErroneos: [],
      dependencias: [],
      tableHeaders: [
        { text: "#", value: "numero" },
        { text: "Propietario", value: "propietarioBusqueda" },
        { text: "Suplente", value: "suplenteBusqueda" },
        { text: "Dependencia", value: "dependencia" },
        { text: "División o Depto", value: "divisionDepto" },
        { text: "", value: "options" },
      ],
      pagina: 1,
      paginas: 1,
      agregarConsejero: false,
      agregarCSV: false,
      mostrarConsejerosErroneos: false,
      modificarDatos: {
        mostrar: false,
        idConsejero: null,
        dependencia: null,
        divisionDepto: null,
      },
      modificarConsejero: {
        mostrar: false,
        idConsejero: null,
        tipo: null,
      },
      eliminarSuplente: {
        mostrar: false,
        idConsejero: null,
        suplente: null,
      },
      tab:0,
      enviando:false,
    };
  },
  // mounted() {
    
  // },
  methods: {
    async cargarConsejo() {
      try {
        this.loading = true;
        const serverResponse = await getConsejoService(this.idConsejo);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$notificarError(serverResponse.mensaje);
        } else {
          this.consejo = serverResponse.consejo;
          this.consejeros = serverResponse.consejeros;
          this.dependencias = serverResponse.dependencias;
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    consejeroAgregado({ consejero, consejerosErroneos = null }) {
      this.agregarCSV = false;
      this.consejeros.push(...((consejero.length !== undefined)? consejero : [consejero]));
      if (consejerosErroneos != null) 
        this.consejerosErroneos = consejerosErroneos;
    },
    modificarDatosAction(data) {
      this.modificarDatos.idConsejero = data.idConsejero;
      this.modificarDatos.dependencia = data.dependencia;
      this.modificarDatos.divisionDepto = data.divisionDepto;
      this.modificarDatos.mostrar = true;
    },
    asignarPropietarioAction(idConsejero) {
      this.modificarConsejero.idConsejero = idConsejero;
      this.modificarConsejero.tipo = "propietario";
      this.modificarConsejero.mostrar = true;
    },
    asignarSuplenteAction(idConsejero) {
      this.modificarConsejero.idConsejero = idConsejero;
      this.modificarConsejero.tipo = "suplente";
      this.modificarConsejero.mostrar = true;
    },
    eliminarSuplenteAction(data) {
      this.eliminarSuplente.idConsejero = data.idConsejero;
      this.eliminarSuplente.suplente = data.suplente;
      this.eliminarSuplente.mostrar = true;
    },
    consejeroModificado(data) {
      const indexConsejero = this.consejeros.findIndex(
        (x) => x._id == data.idConsejero
      );
      
      if (data.tipo == "propietario") {
        this.consejeros[indexConsejero].categoria = data.categoria;
        this.consejeros[indexConsejero].usuarioMaster = data.usuarioMaster;
      } else {
        this.consejeros[indexConsejero].suplente.categoria = data.categoria;
        this.consejeros[indexConsejero].suplente.usuarioMaster = data.usuarioMaster;

        if (data.fromCSV?? false) {
          this.agregarCSV = false;
          this.mostrarConsejerosErroneos = true;
        }
      }
      this.cargarConsejo();
      this.limpiarModificarConsejero();
      this.limpiarEliminarSuplente();
    },
    datosModificados(data) {
      const indexConsejero = this.consejeros.findIndex(
        (x) => x._id == data.idConsejero
      );
      this.consejeros[indexConsejero].dependencia._id = data.dependencia;
      this.consejeros[indexConsejero].dependencia.nombre = data.nombreDependencia;
      this.consejeros[indexConsejero].divisionDepto = data.divisionDepto;
      this.limpiarModificarDatos();
    },
    limpiarModificarConsejero() {
      this.modificarConsejero.idConsejero = null;
      this.modificarConsejero.tipo = null;
      this.modificarConsejero.mostrar = false;
    },
    limpiarModificarDatos() {
      this.modificarDatos.idConsejero = null;
      this.modificarDatos.dependencia = null;
      this.modificarDatos.divisionDepto = null;
      this.modificarDatos.mostrar = false;
    },
    limpiarEliminarSuplente() {
      this.eliminarSuplente.idConsejero = null;
      this.eliminarSuplente.suplente = null;
      this.eliminarSuplente.mostrar = false;
    },
    modificarCorreo(correo){
      this.consejo['correo'] = correo;
    }
  },
};
</script>