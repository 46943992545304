<template>
  <v-dialog v-model="mostrar" fullscreen persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat>
        <h3>Consejeros Erroneos</h3>
        <v-spacer></v-spacer>
        <v-btn small outlined :loading="loading" @click="$emit('cancelar')"
          >Cerrar</v-btn
        >
      </v-toolbar>
      <v-container>
        <v-card outlined>
          <v-card-title>
            <v-btn color="primary" @click="descargarListado()" :class="{ 'disable-events': loading }">Descargar Listado</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="registrarMaster()" :class="{ 'disable-events': loading }">Registrar en Master</v-btn>
          </v-card-title>
          <v-divider class="mx-3"></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Código</th>
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Estatus</th>
                  <th class="text-left">Suplente</th>
                  <th class="text-left">Codigo</th>
                  <th class="text-left">Estatus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(consejero, index) in consejeros" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ consejero.codigo }}</td>
                  <td>{{ consejero.nombre }}</td>
                  <td>{{ consejero.status }}</td>
                  <td>{{ consejero.suplente.nombre }}</td>
                  <td>{{ consejero.suplente.codigo }}</td>
                  <td>{{ consejero.suplente.status }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { setUsuarioNuevoMaster } from "./consejos.service";

export default {
  name: "consejerosErroneos",
  props: ["mostrar", "consejeros"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    descargarListado() {
      const csv = this.convertirCSV();
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "Consejeros.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    convertirCSV() {
      let csv = '"codigo","correo","nombre","Status"\n';
      this.consejeros.forEach(consejero => {
        const suplente = consejero.suplente;
        
        csv += `"${consejero.codigo}",`;
        csv += `"${(consejero.correo == "")? "correo@correo.com" : consejero.correo}",`;
        csv += `"${consejero.nombre}",`;
        csv += `""\n`;
        if (suplente.codigo != ""){
          csv += `"${suplente.codigo}",`;
          csv += `"${(suplente.correo == "")? "correo@correo.com" : suplente.correo}",`;
          csv += `"${suplente.nombre}",`;
          csv += `""\n`;
        }
      });
      return csv;
    },
    async registrarMaster() {
      this.loading = true;
      for (let i = 0; i < this.consejeros.length; i++) {
        const consejero = this.consejeros[i];
        const sendData = {
          codigo: consejero.codigo,
          correo: (consejero.correo == "")? "correo@correo.com" : consejero.correo,
        };
        const serverResponse = await setUsuarioNuevoMaster(sendData);
        const suplenteResponse = await this.registrarSuplenteMaster(consejero.suplente);
        consejero.status = (serverResponse.usuario == "No se pudo registrar al usuario")? serverResponse.usuario : serverResponse.mensaje;
        consejero.suplente.status = (suplenteResponse.usuario == "No se pudo registrar al usuario")? suplenteResponse.usuario : suplenteResponse.mensaje;
      }
      this.loading = false;
      // this.$emit('cancelar');
    },
    async registrarSuplenteMaster(consejero) {
      if (consejero.codigo == "") return "";
      const sendData = {
        codigo: consejero.codigo,
        correo: (consejero.correo == "")? "correo@correo.com" : consejero.correo,
      };
      const serverResponse = await setUsuarioNuevoMaster(sendData);
      return serverResponse;
    },
  },
};
</script>

<style>
</style>