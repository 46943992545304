<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3>Modificar datos</h3></v-toolbar
      >
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <v-autocomplete
             auto-select-first
              v-model="idDependencia"
              :items="dependencias"
              label="Dependencia"
              item-text="nombre"
              item-value="_id"
              color="blue-grey"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="divisionDepto"
              name="SG-divisionDepto"
              label="División o Departamento"
              type="text"
              color="blue-grey"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          :class="{ 'disable-events': blockAceptar }"
          @click="modificarDatos()"
          >Modificar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { modificarDatosService } from "./consejos.service";

export default {
  name: "modificarDatosConsejero",
  props: ["mostrar", "idConsejero", "dependenciaPadre", "divisionDeptoPadre", "dependencias"],
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userAdmin"]),
    blockAceptar() {
      if (this.loading) return true;
      if (!this.idDependencia) return true;
      return false;
    },
  },
  validations: {
    dependencia: { required },
  },
  data() {
    return {
      loading: false,
      idDependencia: null,
      dependencia: null,
      divisionDepto: "",
    };
  },
  mounted() {
    this.idDependencia = this.dependenciaPadre;
    this.divisionDepto = this.divisionDeptoPadre;
  },
  methods: {
    async modificarDatos() {
      try {
        this.loading = true;
        let sendData = {
          idConsejero: this.idConsejero,
          dependencia: this.idDependencia,
          divisionDepto: this.divisionDepto,
        };
        const serverResponse = await modificarDatosService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          throw new Error(serverResponse.mensaje);
        } else {
          this.dependencias.forEach(dependencia => {
            if(dependencia._id === this.idDependencia) sendData.nombreDependencia = dependencia.nombre;
          });
          this.$emit("datosModificados", sendData);
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>
